/* eslint-disable no-debugger, no-console */
import React, { FunctionComponent, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, useHistory, RouteProps } from "react-router-dom";
import { DefaultLayout } from "pages/_layouts/default";
import AuthLayout from "pages/_layouts/auth";
import AdminLayout from "pages/_layouts/admin";
import { UserManager } from "oidc-client";
import OidcSettings from "./OidcSettings";
import store from "app/store";
import {
  setSSOAccessToken,
  ssoLogin,
} from "pages/SSOLogin/user.slice";

declare global {
  interface Window {
    userManager: any;
  }
}
// function onUserLoaded(user: any) {
//   console.log("user == " +user);
//   postData('http://localhost:8080/account/v1/ssoLogin',{
//     "type": "assure",
//     "idToken": user.access_token })
//   .then(data => {
//     console.log(data); // JSON data parsed by `data.json()` call
//     localStorage.setItem("accessToken", data.data.user.AccessToken);
//   });
// }
// function onUserUnloaded() {
//   console.log("User unloaded");
// }

// async function postData(url = '', data = {}) {
//   // Default options are marked with *
//   const response = await fetch(url, {
//     method: 'POST', // *GET, POST, PUT, DELETE, etc.
//     mode: 'cors', // no-cors, *cors, same-origin
//     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//     credentials: 'same-origin', // include, *same-origin, omit
//     headers: {
//       'Content-Type': 'application/json'
//       // 'Content-Type': 'application/x-www-form-urlencoded',
//     },
//     redirect: 'follow', // manual, *follow, error
//     referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
//     body: JSON.stringify(data) // body data type must match "Content-Type" header
//   });
//   return response.json(); // parses JSON response into native JavaScript objects
// }

const userManager = new UserManager(OidcSettings);
window["userManager"] = userManager;
window["userManager"].events.addUserLoaded(onUserLoaded);
window["userManager"].events.addUserUnloaded(onUserUnloaded);

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
  isSignedIn?: boolean;
  isPrivate?: boolean;
  hideHeader?: boolean;
  adminRoute?: boolean;
  hospitalRoute?: boolean;
  protectedRoute?: boolean;
  title?: string;
}

export default function RouteWrapper({
  component: Component,
  isPrivate,
  adminRoute,
  protectedRoute,
  hospitalRoute,
  hideHeader,
  ...rest
}: PrivateRouteProps) {
  const history = useHistory();

  let Layout: FunctionComponent<any> = DefaultLayout;
  if (hideHeader) {
    Layout = AuthLayout;
  } else if (adminRoute) {
    Layout = AdminLayout;
  }

  useEffect(() => {
    userManager
      .getUser()
      .then((user) => {
        if (user !== null && user !== undefined) {
          onUserLoaded(user);
        } else if (window.location.href.includes("#id_token")) {
          userManager
            .signinRedirectCallback()
            .then(() => {
              window.history.replaceState({}, "", "/");
            })
            .catch((err) => {
              console.error(err);
            });
        } else if (isPrivate) {
          history.push("/");
        }
      })
      .catch((error) => {
        console.error("error inside catch block :: ", error);
      });
  }, []);

  /**
   * If not included on both previous cases, redirect user to the desired route.
   */
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

function onUserLoaded(user: any) {
  // console.log("UserLoaded...........");
  if (user.state?.tenantId) {
    user.profile.tenantId = user.state?.tenantId;
  }
  // console.log("SSO TOKEN DETAILS:", user);
  store.dispatch(ssoLogin(user.profile));
  store.dispatch(setSSOAccessToken(String(user.access_token)));
}

function onUserUnloaded() {
  // console.log("User LOGOUT");
  store.dispatch(ssoLogin({ user: {} }));
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  hideHeader: false,
  adminRoute: false,
  hospitalRoute: false,
  protectedRoute: false,
  isSignedIn: false,
};
