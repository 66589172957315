import { axiosAccount } from "./ApiServices";

export interface Credentials {
  email: string;
  password: string;
  validate?: boolean;
  touch?: boolean;
  idToken: string;
}
export interface Token {
  type: string;
  idToken: string;
}
export const adminSignIn = async (user: Credentials): Promise<any> => {
  return axiosAccount.post("/adminLogin?permissionData=true", user);
};
export const ssoSignUp = async (token: Token): Promise<any> => {
  return axiosAccount.post("/ssoLogin", token);
};
// export const getClientSecret = async (code :any) : Promise<any> =>{
//     return axiosAccount.get(`/clientCredentials?code=${code.join()}`);
// }
// export const getUserByFilter = async (content: any): Promise<any> => {
//       const vendorId = content.filter.vendorsId;
//     return axiosAccount.get(
//       `/users?pageSize=${content.pageSize}&pageOrder=${content.pageOrder}&q=${content.search}&pageNumber=${content.pageNumber}&vendorId=${vendorId.join(",")}`
//     );
//   };
