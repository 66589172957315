import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SchedulerManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
  active: string;
  reFetch: boolean,
  flag: boolean,
}

export const initialState: SchedulerManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
  active: "true",
  reFetch: false,
  flag: false,
};

const schedulerManagementSlice = createSlice({
  name: "reportSchedulerList",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<SchedulerManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    setFlag: (state, action: PayloadAction<SchedulerManagementState>) => {
      state.flag = action.payload.flag;
    },
    searchScheduler: (
      state,
      action: PayloadAction<SchedulerManagementState>
    ) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (state, action: PayloadAction<SchedulerManagementState>) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    filterByStatus: (state, action: PayloadAction<string>) => {
      state.active = action.payload;
    },
    toggleReFetch: (state, action: PayloadAction<SchedulerManagementState>) => {
      if(state.flag && action.payload.pageNumber > 1) {
        state.pageNumber = action.payload.pageNumber - 1;
        state.flag = false;
      }
      state.reFetch = !state.reFetch;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearSchedulerState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = schedulerManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchScheduler,
  setPageSize,
  filterByStatus,
  clearSchedulerState,
  toggleReFetch,
  setFlag,
} = actions;
export default reducer;
