import React, { useState, FunctionComponent, Suspense } from "react";
import AdminHeader from "components/AdminHeader";
import AdminFooter from "components/AdminFooter";
import AdminLeftPanel from "components/AdminLeftPanel";
import Loader from "components/Loader";
import Toast from "components/Toast";

import AdminWrapper from "./styled";
import { useEffect } from "react";
import classNames from "classnames";

export interface AdminLayoutProps {}

const MainComponent = React.lazy(() => import("./mainContentFragment"));

export const AdminLayout: FunctionComponent<AdminLayoutProps> = (props) => {
  const [toggleNavBar, setNavbar] = useState(false);
  const [toggleMainContent, setMainContent] = useState(false);
  const [showSidePanel, setSidePanel] = useState(true);

  const toggle = () => {
    setNavbar((isToggleNavBar) => !isToggleNavBar);
    setMainContent((isToggleMainContent) => !isToggleMainContent);
  };

  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 991;

  return (
    <AdminWrapper>
      <div className="left-panel">
        <div
          className={classNames("sidenav ", {
            "left-nav-bar-show": toggleNavBar && isMobile,
            "left-nav-bar": !toggleNavBar || !isMobile,
          })}
          style={showSidePanel ? {} : { width: "4.9rem" }}
        >
          <AdminLeftPanel
            toggle={toggle}
            {...props}
            setSidePanel={setSidePanel}
            showSidePanel={showSidePanel}
          />
        </div>

        <div
          className={classNames("d-flex flex-column  main ", {
            "main-content-hide": toggleMainContent,
            "main-content": !toggleMainContent,
          })}
          style={
            showSidePanel
              ? { marginLeft: "20%", overflow: "hidden" }
              : { marginLeft: "6%", overflow: "hidden" }
          }
        >
          <AdminHeader
            toggle={toggle}
            {...props}
            setSidePanel={setSidePanel}
            showSidePanel={showSidePanel}
          />
          {/* <ScrollToTop /> */}
          <Toast />

          <main className="d-flex flex-column flex-grow-1" id="maincontent">
            <Suspense
              fallback={
                <div
                  className="aui-loader"
                  role="alert"
                  aria-live="assertive"
                  aria-label="Page is loading"
                />
              }
            >
              <MainComponent {...props} />
            </Suspense>
          </main>

          <Loader />
          <AdminFooter />
        </div>
      </div>
    </AdminWrapper>
  );
};
