export const ParentTags = {
  MISMATCH: "Mismatch",
  REJECTED: "Rejected",
} as const;

export type ParentTags = typeof ParentTags[keyof typeof ParentTags];

export const MismatchErrorTags = [
  // { Code: "Learner Identifier" },
  { Code: "Address" },
  { Code: "Email" },
  { Code: "Name" },
] as const;

export const RejectedErrorTags = [
  { Code: "Classification issue" },
  { Code: "Certificate issue" },
  { Code: "Credit issue" },
  { Code: "Date errors" },
  { Code: "Character length mismatch" },
  { Code: "Field empty" },
  { Code: "Others" },
] as const;
