/* eslint-disable no-debugger, no-console */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ssoSignUp } from "services/api/adminLogin.api";
import { SSOLoginWrapper } from "./styled";
import { useHistory } from "react-router-dom";
import store from "../../app/store";
import { LocalStorageKeys } from "../../common/constants/localStorage";
import {useRBACContext} from "@impelsysinc/react-rbac";

export interface Token {
  type: string;
  idToken: string;
}

const Landing = (props: any) => {
  const [count, setCount] = useState<number>(0);
  const history = useHistory();
  const userState = useSelector((state: any) => {
    return state.userManagement;
  });
  const { setPermissions }  = useRBACContext()

  const signIn = async (tokens: Token) => {
    ssoSignUp(tokens)
      .then((response: any) => {
        const result = response.data;
        const data = result.data;
        localStorage.setItem("accessToken", data.user.AccessToken);

        if (data?.user?.Permissions?.permissions) {
          const permissions = data?.user?.Permissions?.permissions;
          setPermissions(permissions);
          localStorage.setItem(
            LocalStorageKeys.PERMISSIONS,
            JSON.stringify(permissions)
          );
        }

        history.push("/learners");
      })
      .catch((error) => {
        history.push("/invalidUser");
      });
  };

  useEffect(() => {
    if (userState.ssoAccessToken && count === 1) {
      setCount(2);
      signIn({ type: "assure", idToken: userState.ssoAccessToken });
    }
  }, [userState.ssoAccessToken, count]);

  useEffect(() => {
    setCount(1);
  }, []);

  return (
    <SSOLoginWrapper>
      <div className="container">
        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center progress-welcome">
          <h1 className="h2 mb-0 mr-sm-3 mt-3 font-600  progress-welcome-title">
            Welcome to <span className="font-700">CE Service</span>
          </h1>
          <img
            src="/images/progress_logo_animation.gif"
            alt="logo"
            className="progress-img"
          />
        </div>
      </div>
    </SSOLoginWrapper>
  );
};

export default Landing;
