import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ErrorListState {
  pageNumber: number;
  search: string;
  filterByVendor: string;
  filterByDate: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
  reFetch: boolean;
  parentTags: string[];
  tags?: string[];
}

const getDateRange = () => {
  const dates = new Date();

  return `${dates.getFullYear()}-${("0" + (dates.getMonth() + 1)).slice(-2)}-${(
    "0" + dates.getDate()
  ).slice(-2)}~${dates.getFullYear()}-${("0" + (dates.getMonth() + 1)).slice(
    -2
  )}-${("0" + dates.getDate()).slice(-2)}`;
};

export const initialState: ErrorListState = {
  pageNumber: 1,
  search: "",
  filterByVendor: "",
  filterByDate: getDateRange(),
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
  reFetch: false,
  parentTags: ["Mismatch"],
  tags: [],
};

const ErrorListSlice = createSlice({
  name: "errorList",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<ErrorListState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchActivityErrors: (state, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload;
    },
    filterByVendor: (state, action: PayloadAction<string>) => {
      state.filterByVendor = action.payload;
    },
    filterByDate: (state, action: PayloadAction<string>) => {
      state.filterByDate = action.payload;
    },
    filterByTags: (state, action: PayloadAction<{tags: string[]; parentTags: string[] }>) => {
      state.tags = action.payload.tags;
      state.parentTags = action.payload.parentTags;
    },
    setPageSize: (state, action: PayloadAction<ErrorListState>) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    toggleReFetch: (state, action: PayloadAction<boolean>) => {
      state.reFetch = !state.reFetch;
      state.pageNumber = 1;
    },
    clearErrorState: () => {
      return initialState;
    },
  },
});

const { actions, reducer } = ErrorListSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchActivityErrors,
  filterByVendor,
  filterByDate,
  filterByTags,
  setPageSize,
  clearErrorState,
  toggleReFetch,
} = actions;

export default reducer;
