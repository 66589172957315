import React, { useState, useEffect, FunctionComponent } from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import PropTypes from "prop-types";
import LeftPanelWrapper from "./styled";
import { useTranslation } from "react-i18next";
import * as constants from "common/constants";
import {
  Accordion,
  AccordionBody,
  AccordionItem,
  AccordionHeader,
} from "components/Accordion";
import store from "../../app/store";
import { Signout } from "services/api/AdminHeader.api";
import { showToast } from "components/Toast/toast.slice";
import { useRBACContext } from "@impelsysinc/react-rbac";
import classNames from "classnames";

export const useSubmenu = () => {
  const { t } = useTranslation();
  const { canAccess } = useRBACContext();

  return {
    component: [
      {
        path: "/vendors",
        name: "Vendors",
        displayName: `${t<string>("vendors")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "vendors",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/accreditations",
        name: "Accreditations",
        displayName: `${t<string>("accreditations")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "accreditations",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/programs",
        name: "Programs",
        displayName: `${t<string>("programs")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "programs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/classifications",
        name: "Classifications",
        displayName: `${t<string>("classifications")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "classifications",
          action: ["get", "get.all"],
        }),
      },
    ],

    accr_certificate: [
      {
        path: "/certificates",
        name: "Certificates",
        displayName: `${t<string>("certifications")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "certificates",
          action: ["get", "get.all"],
        }),
      },
    ],

    auditLog_menu: [
      {
        path: "/auditlogs",
        name: "AuditLog",
        displayName: `${t<string>("auditlog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "auditLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/activitylogs",
        name: "ActivityLogs",
        displayName: `${t<string>("activitylog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "activityLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/batchlogs",
        name: "BatchLog",
        displayName: `${t<string>("batchlog")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "batchLogs",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/reportlogs",
        name: "ReportLog",
        displayName: `${t<string>("Report Log")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "reportLogs",
          action: ["get", "get.all"],
        }),
      },
    ],

    activitiesMenu: [
      {
        path: "/learners",
        name: "learnerDetails",
        displayName: `${t<string>("learnerDetails")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "learnerDetails",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/activities",
        name: "SuccessActivities",
        displayName: `${t<string>("successActivities")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "successActivities",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/activity-errors",
        name: "errorActivities",
        displayName: `${t<string>("errorList")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "errorActivities",
          action: ["get", "get.all"],
        }),
      },
    ],

    archivedFilesMenu: [
      {
        path: constants.Routes.ARCHIVE_LEARNER_LIST_PAGE,
        name: "learnerDetails",
        displayName: `${t<string>("learnerDetails")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "archivedLearnerDetails",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/archived-activities",
        name: "SuccessActivities",
        displayName: `${t<string>("successActivities")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "archivedActivities",
          action: ["get", "get.all"],
        }),
      },
      {
        path: "/archived-activity-errors",
        name: "errorActivities",
        displayName: `${t<string>("errorList")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "archivedErrorActivities",
          action: ["get", "get.all"],
        }),
      },
    ],

    schedulerMenu: [
      {
        path: "/schedulers",
        name: "SchedulerList",
        displayName: `${t<string>("schedulerList")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "schedulerList",
          action: ["get", "get.all"],
        }),
      },
    ],
    reports: [
      {
        path: "/reports",
        name: "reports",
        displayName: `${t<string>("reports")}`,
        hide: !canAccess({
          resourceType: "menu",
          resource: "reports",
          action: ["get", "get.all"],
        }),
      },
    ],
  };
};

export interface AdminLeftPanelProps {
  toggle: Function;
  setSidePanel: Function;
  showSidePanel: boolean;
}

export const AdminLeftPanel: FunctionComponent<AdminLeftPanelProps> = (
  props: any
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { path: currUrlPath } = useRouteMatch();

  const [parentId, setParentId] = useState<string | string[]>("activityMenu");
  const activeMenuHandler = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>
  ) => {
    props.toggle(event);
  };

  const [AccreSubmenu, setAccreSubmenu] = useState(false);
  const { canAccess, clearPermissions } = useRBACContext();

  const canAccessUserManagementList = canAccess({
    resourceType: "component",
    resource: "userManagement",
    action: "get.all",
  });

  const subMenuArray = useSubmenu();

  const Global: any = global;

  const signout = async () => {
    const res = await Signout();
    if (res?.status === 200) {
      localStorage.removeItem("Authorization");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("apiPermissions");
      clearPermissions();
      Global.userManager.signoutRedirect();
    } else {
      store.dispatch(
        showToast({
          title: "Signout",
          message: res?.data,
        })
      );
    }
  };

  useEffect(() => {
    if (!props.showSidePanel) {
      setParentId("");
    }
  }, [props.showSidePanel]);

  useEffect(() => {
    let activeMenu = "";

    [...subMenuArray.component, ...subMenuArray.accr_certificate].forEach(
      ({ path }) => {
        if (currUrlPath?.indexOf(path) !== -1) {
          activeMenu = "components";
        }
      }
    );

    subMenuArray.auditLog_menu.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "auditLogMenu";
      }
    });

    subMenuArray.activitiesMenu.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "activityMenu";
      }
    });

    subMenuArray.archivedFilesMenu.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "archivedFilesMenu";
      }
    });

    subMenuArray.schedulerMenu.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "schedulerMenu";
      }
    });

    subMenuArray.reports.forEach(({ path }) => {
      if (currUrlPath?.indexOf(path) !== -1) {
        activeMenu = "reports";
      }
    });

    setParentId(activeMenu);
  }, [currUrlPath]);

  const toggle = (id: string) => {
    if (parentId === id) {
      setParentId("");
    } else {
      setParentId(id);
    }
  };

  return (
    <LeftPanelWrapper>
      <div className="aui-sidenav">
        <nav
          className="navbar navbar-expand-lg logo"
          id="navbar"
          aria-label="Main"
          role="navigation"
        >
          <a href="/learners" aria-label="CE Service logo">
            <img
              src={
                props.showSidePanel
                  ? process.env.PUBLIC_URL + "/images/Logo_txt_Main CECME.svg"
                  : process.env.PUBLIC_URL + "/images/Heart_Logo.svg"
              }
              alt="content icon"
              className="headerLogo-style"
              style={props.showSidePanel ? {} : { width: "60%" }}
            />
            {/* <SvgCeCmeLogo className="headerLogo-style" /> */}
          </a>
          <span className="close" onClick={props.toggle}>
            X
          </span>
        </nav>
      </div>
      <div className="expandButton d-flex">
        <button
          aria-label="Expand or collapse navigation"
          type="button"
          className="css-vn3wav d-none d-sm-none d-md-none d-lg-flex d-xl-flex"
          style={
            props.showSidePanel
              ? { backgroundColor: "#c33626", color: "white" }
              : {}
          }
          onClick={() => props.setSidePanel((state: boolean) => !state)}
        >
          <i
            className={
              props.showSidePanel
                ? "acc-btn-arrow aha-icon-arrow-down leftDireaction"
                : "acc-btn-arrow aha-icon-arrow-down rightDireaction"
            }
          ></i>
        </button>
      </div>
      <div className="aui-sidenav left-panel-contents">
        <nav
          style={
            props.showSidePanel ? { display: "block" } : { display: "none" }
          }
          className="navbar-expand-lg overflow-hidden"
          id="main-nav"
          aria-label="Component Navbar"
        >
          <div className="" id="sideNavbar">
            <Accordion
              flush
              open={parentId}
              tag="ul"
              className="w-100"
              toggle={toggle}
            >
              <AccordionItem tag="li" id="sidenav-accordion-dropdown_0">
                <AccordionHeader
                  tag="button"
                  targetId="myaccount"
                  className={classNames(
                    "btn btn-text aui-sidenav-acc-header d-lg-none d-xl-none",
                    {
                      parentHighlight: parentId === "myaccount",
                    }
                  )}
                  id="sidenavHeadingOne"
                  aria-label="myaccount"
                  aria-expanded={parentId === "myaccount"}
                  aria-controls="myaccount"
                >
                  <span>
                    <img
                      src={
                        process.env.PUBLIC_URL + "/images/side-nav-avatar.svg"
                      }
                      alt="avatar"
                    />
                  </span>
                  <p className="mb-0">
                    <> {t<string>("myAccount")}</>
                  </p>
                  <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                </AccordionHeader>
                <AccordionBody
                  tag="ul"
                  className={"aui-sidenav-acc-body"}
                  accordionId="myaccount"
                  id="myaccount"
                  aria-labelledby="sidenavHeadingOne"
                >
                  <li className="">
                    <a
                      onClick={signout}
                      className="dropdown-item py-2"
                      role="button"
                      // onKeyUp={signout}
                      tabIndex={0}
                    >
                      <> {t<string>("signOut")}</>
                    </a>
                  </li>
                </AccordionBody>
              </AccordionItem>
              {/* <li id="hide_desktop" className="hide_desktop show_mobile">
                <button
                  className="btn btn-text aui-sidenav-acc-header"
                  id="sidenavcollapseMyAcc"
                  // data-toggle="collapse"
                  // data-target="#sidenavcollapseSignOut"
                  aria-expanded="false"
                  aria-controls="sidenavcollapseSignOut"
                >
                  {t<string>("myAccount")}
                  <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                </button>

                <ul
                  id="sidenavcollapseSignOut"
                  className="aui-sidenav-acc-body collapse"
                  aria-labelledby="sidenavcollapseMyAcc"
                >
                  <li className="">
                    <a
                      onClick={signout}
                      className="dropdown-item py-2"
                      role="button"
                      onKeyUp={signout}
                      tabIndex={0}
                    >
                      {t<string>("signOut")}
                    </a>
                  </li>
                </ul>
              </li> */}

              {/* <li>
                <NavLink
                  to={dashboardPath}
                  aria-label={t<string>("dashboard")}
                  activeClassName="active"
                  className="aui-sidenav-acc-header"
                  onClick={(event) => activeMenuHandler(event, subMenuArray)}
                >
                  {t<string>("dashboard")}
                </NavLink>
              </li> */}

              {canAccessUserManagementList && (
                <li
                  id="sidenav-accordion-dropdown_5"
                  className="d-lg-none d-xl-none"
                >
                  <button
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "users",
                      }
                    )}
                    id="headingArchivedFiles"
                    onClick={(event) => {
                      activeMenuHandler(event);
                      history.push("/users");
                    }}
                  >
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/side-nav-user-permissions.svg"
                        }
                        alt="user permissions"
                      />
                    </span>
                    <p className="d-width no-width">
                      <> {t<string>("userManagement")}</>
                    </p>
                  </button>
                </li>
              )}

              {/* Components Menu Item */}
              {subMenuArray.component.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_1">
                  <AccordionHeader
                    targetId="components"
                    tag="button"
                    className={classNames(
                      "w-100 btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "components",
                      }
                    )}
                    id="sidenavHeadingTwo"
                    aria-expanded={parentId === "components"}
                    aria-controls="sidenavcollapseOne"
                  >
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/side-nav-component.svg"
                        }
                        alt="components"
                      />
                    </span>
                    <p>
                      <> {t<string>("components")}</>
                    </p>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    accordionId="components"
                    id="sidenavcollapseOne"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="sidenavHeadingTwo"
                  >
                    {subMenuArray.component.map((list, i) => {
                      return list.hide ? null : (
                        <li
                          key={i}
                          className={classNames({
                            "Accr_parentMenu certificate_submenu":
                              list.name === "Accreditations",
                          })}
                        >
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("components");
                              activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}

                            {/* Accreditation Menu Item */}
                            {list.name === "Accreditations" &&
                              subMenuArray.accr_certificate.some(
                                (item) => !item.hide
                              ) && (
                                <span
                                  className={classNames("submenu_toggleIcon", {
                                    iconMinus_style: AccreSubmenu,
                                    iconPlus_style: !AccreSubmenu,
                                  })}
                                  onClick={(
                                    e: React.MouseEvent<HTMLSpanElement>
                                  ) => {
                                    e.preventDefault();
                                    if (e.stopPropagation) {
                                      e.stopPropagation(); // W3C model
                                    } else {
                                      e.cancelable = true; // IE model
                                    }
                                    setAccreSubmenu(
                                      (isAccreSubmenu) => !isAccreSubmenu
                                    );
                                    setParentId("components");
                                  }}
                                >
                                  {AccreSubmenu ? "-" : "+"}
                                </span>
                              )}
                          </NavLink>

                          {list.name === "Accreditations" &&
                            subMenuArray.accr_certificate.map(
                              (list_inner, i_inner) => {
                                return list_inner.hide ? null : (
                                  <ul
                                    key={i_inner}
                                    id={`certificate_submenu_${i}`}
                                    className={classNames(
                                      "pb-0 collapse certi_substyle ",
                                      {
                                        show: AccreSubmenu,
                                      }
                                    )}
                                  >
                                    <li key={i_inner}>
                                      <NavLink
                                        activeClassName="active"
                                        className=""
                                        key={list_inner.path}
                                        aria-label={list_inner.name}
                                        to={list_inner.path}
                                        onClick={(event) => {
                                          setParentId("components");
                                          activeMenuHandler(event);
                                        }}
                                      >
                                        {list_inner.displayName}
                                      </NavLink>
                                    </li>
                                  </ul>
                                );
                              }
                            )}
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* Activities Menu Item */}
              {subMenuArray.activitiesMenu.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_2">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header ",
                      {
                        parentHighlight: parentId === "activityMenu",
                      }
                    )}
                    id="headingActivities"
                    targetId="activityMenu"
                    aria-expanded={parentId === "activityMenu"}
                    aria-controls="sidenavActivityContainer"
                  >
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/side-nav-activities.svg"
                        }
                        alt="activity menu"
                      />
                    </span>
                    <p>
                      <> {t<string>("sideNavActivitylbl")}</>
                    </p>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavActivityContainer"
                    accordionId="activityMenu"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="headingActivities"
                  >
                    {subMenuArray.activitiesMenu.map((list, i) => {
                      return list.hide ? null : (
                        <li key={i} className="">
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("activityMenu");
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}
                          </NavLink>
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* Archived Files Menu Item */}
              {subMenuArray.archivedFilesMenu.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_4">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "archivedFilesMenu",
                      }
                    )}
                    id="headingArchivedFiles"
                    targetId="archivedFilesMenu"
                    aria-expanded={parentId === "archivedFilesMenu"}
                    aria-controls="sidenavheadingArchivedFilesContainer"
                  >
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/side-nav-archive.svg"
                        }
                        alt="archived files menu"
                      />
                    </span>
                    <p>
                      <> {t<string>("sideNavArchiveFilelbl")}</>
                    </p>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavheadingArchivedFilesContainer"
                    accordionId="archivedFilesMenu"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="headingArchivedFiles"
                  >
                    {subMenuArray.archivedFilesMenu.map((list, i) => {
                      return list.hide ? null : (
                        <li key={i} className="">
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("archivedFilesMenu");
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}
                          </NavLink>
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* <button>
                <NavLink
                  to="/reports"
                  aria-label={t<string>("reports")}
                  activeClassName="active"
                  className="btn btn-text aui-sidenav-acc-header "
                  onClick={(event) => activeMenuHandler(event)}
                >
                  {t<string>("reports")}
                </NavLink>
              </button> */}

              {/* Reports Menu Item */}
              {subMenuArray.reports.some((item) => !item.hide) && (
                <li id="sidenav-accordion-dropdown_5">
                  <button
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "reports",
                      }
                    )}
                    id="headingArchivedFiles"
                    onClick={(event) => {
                      activeMenuHandler(event);
                      setParentId("reports");
                      history.push("/reports");
                    }}
                  >
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/side-nav-reports.svg"
                        }
                        alt="reports"
                      />
                    </span>
                    <p className="d-width no-arrow">
                      <> {t<string>("reports")}</>
                    </p>
                  </button>
                </li>
              )}

              {/* Scheduler Menu Item */}
              {subMenuArray.schedulerMenu.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_5">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "schedulerMenu",
                      }
                    )}
                    id="headingArchivedFiles"
                    targetId="schedulerMenu"
                    aria-expanded={parentId === "schedulerMenu"}
                    aria-controls="sidenavheadingReportContainer"
                  >
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/images/side-nav-scheduler.png"
                        }
                        alt="activity menu"
                      />
                    </span>
                    <p>
                      <> {t<string>("sideNavSchedulerFilelbl")}</>
                    </p>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavheadingReportContainer"
                    accordionId="schedulerMenu"
                    className="aui-sidenav-acc-body"
                    aria-labelledby="headingArchivedFiles"
                  >
                    {subMenuArray.schedulerMenu.map((list, i) => {
                      return (
                        <li key={i} className="">
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              setParentId("schedulerMenu");
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}
                          </NavLink>
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}

              {/* Logs Menu Item */}
              {subMenuArray.auditLog_menu.some((item) => !item.hide) && (
                <AccordionItem tag="li" id="sidenav-accordion-dropdown_3">
                  <AccordionHeader
                    tag="button"
                    className={classNames(
                      "btn btn-text aui-sidenav-acc-header",
                      {
                        parentHighlight: parentId === "auditLogMenu",
                      }
                    )}
                    id="sidenavHeadingOne_id"
                    targetId="auditLogMenu"
                    aria-expanded={parentId === "auditLogMenu"}
                    aria-controls="sidenavcollapseLog"
                  >
                    <span>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/images/side-nav-logs.svg"
                        }
                        alt="audit log menu"
                      />
                    </span>
                    <p>
                      <> {t<string>("log")}</>
                    </p>
                    <i className="acc-btn-arrow aha-icon-arrow-down"></i>
                  </AccordionHeader>
                  <AccordionBody
                    tag="ul"
                    id="sidenavcollapseLog"
                    accordionId="auditLogMenu"
                    className={"aui-sidenav-acc-body"}
                    aria-labelledby="sidenavHeadingOne_id"
                  >
                    {subMenuArray.auditLog_menu.map((list, i) => {
                      return list.hide ? null : (
                        <li key={i} className="">
                          <NavLink
                            activeClassName="active"
                            className=""
                            key={list.path}
                            aria-label={list.name}
                            to={list.path}
                            onClick={(event) => {
                              return activeMenuHandler(event);
                            }}
                          >
                            {list.displayName}
                          </NavLink>
                        </li>
                      );
                    })}
                  </AccordionBody>
                </AccordionItem>
              )}
            </Accordion>
          </div>
        </nav>
        <div
          className="nav-side-icons-only"
          style={
            props.showSidePanel ? { display: "none" } : { display: "block" }
          }
        >
          {subMenuArray.component.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("components")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("components");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/side-nav-component-blk.svg"
                  }
                  alt="components"
                />
              </button>
            </div>
          )}
          {subMenuArray.activitiesMenu.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("sideNavActivitylbl")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("activityMenu");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/side-nav-activiies-blk.svg"
                  }
                  alt="activity menu"
                />
              </button>
            </div>
          )}
          {subMenuArray.archivedFilesMenu.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("sideNavArchiveFilelbl")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("archivedFilesMenu");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/side-nav-archive-blk.svg"
                  }
                  alt="archived files menu"
                />
              </button>
            </div>
          )}
          {subMenuArray.reports.some((item) => !item.hide) && (
            <div>
              <NavLink
                to="/reports"
                aria-label={t<string>("reports")}
                className="btn btn-text"
                style={{ background: "transparent" }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/side-nav-reports-blk.svg"
                  }
                  alt="reports"
                />
              </NavLink>
            </div>
          )}

          {subMenuArray.schedulerMenu.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("sideNavSchedulerFilelbl")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("schedulerMenu");
                }}
              >
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/images/side-nav-scheduler-blk.png"
                  }
                  alt="scheduler menu"
                />
              </button>
            </div>
          )}

          {subMenuArray.auditLog_menu.some((item) => !item.hide) && (
            <div>
              <button
                aria-label={t<string>("log")}
                className="btn btn-text"
                onClick={() => {
                  props.setSidePanel(true);
                  setParentId("auditLogMenu");
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/images/side-nav-logs-blk.svg"}
                  alt="logs"
                />
              </button>
            </div>
          )}
        </div>
      </div>
    </LeftPanelWrapper>
  );
};

export default AdminLeftPanel;

AdminLeftPanel.propTypes = {
  toggle: PropTypes.func.isRequired,
};
