import axios from "axios";
import config from "Config";
import LocalStore from "common/LocalStore";

// const headers = {
//     'Content-Type': 'application/json'
// }

export const axiosVendor = axios.create({
  baseURL: config[config.env].apiEndpoints.vendor,
});

export const axiosAccount = axios.create({
  baseURL: config[config.env].apiEndpoints.account,
});

axiosAccount.interceptors.request.use((request) => {
  if (request.url?.includes("ssoLogin")) {
    return request;
  }

  LocalStore.get("accessToken").then((response) => {
    if (response) {
      request.headers["Authorization"] = `Bearer ${response}`;
    } else throw new Error("Unauthorized");
  });
  return request;
});

axiosAccount.interceptors.response.use((response) => {
  return response;
});

axiosVendor.interceptors.request.use((request) => {
  LocalStore.get("accessToken").then((response) => {
    if (response) {
      request.headers["Authorization"] = `Bearer ${response}`;
    } else throw new Error("Unauthorized");
  });
  return request;
});

axiosVendor.interceptors.response.use((response) => {
  return response;
});


export const axiosAuditLog = axios.create({
  baseURL: config[config.env].apiEndpoints.auditLog,
});

axiosAuditLog.interceptors.request.use((request) => {

  LocalStore.get("accessToken").then((response) => {
    if (response) {
      request.headers["Authorization"] = `Bearer ${response}`;
    } else throw new Error("Unauthorized");
  });
  return request;
});

axiosAuditLog.interceptors.response.use((response) => {
  return response;
});

export const axiosReporting = axios.create({
  baseURL: config[config.env].apiEndpoints.reporting,
});

axiosReporting.interceptors.request.use((request) => {

  LocalStore.get("accessToken").then((response) => {
    if (response) {
      request.headers["Authorization"] = `Bearer ${response}`;
    } else throw new Error("Unauthorized");
  });
  return request;
});

axiosReporting.interceptors.response.use((response) => {
  return response;
});
