export const CONSTANTS = {
  PENDING: "PENDING",
  REGISTERED: "APPROVED",
  INTERNATIONAL: "INTERNATIONAL",
  DOMESTIC: "DOMESTIC",
  LOCAL_STORE_KEY: "#%$##%$#",

  PAGINATION_LIMIT: 5,
  KEY_ENTER: 13,
  KEY_SPACEBAR: 32,
  KEY_ESCAPE: 27,
  KEY_DOWN: 40,
  KEY_UP: 38,

  TableRecordSizeList: [10, 25, 50, 100],
  SortOptions: ["Latest", "A-Z"],

  ACCREDITATION_PAGEID: "ACCRD",
  USERLIST_PAGEID: "USER",
  ERRORLIST_PAGE: "ERRORLIST",
  AUDITLOG_PAGEID: "AUDITlOG",
  REPORTLOG_PAGEID: "REPORTLOG",
  BATCHLOG_PAGEID: "BATCHlOG",
  ACTIVITYLOG_PAGEID: "ACTIVITYlOG",
  SUCCESS_ACTIVITY: "ACTIVITYLIST",
  CDR_FIELDS: ["cdrRegistrationNumber", "cdrCpeLevel", "cdrPerformanceIndicators", "courseApprovalNumber"],
  ARCHIVED_LEARNER_LIST: "ARCHIVED_LEARNER_LIST",
  ROLES: [
    {id : 1, name: "Super Admin", code: "SUPER_ADMIN"},
    {id : 2, name: "Tenant Admin", code: "ADMIN"},
    {id : 3, name: "Reporting Admin", code: "REPORTING_ADMIN"},
  ],
  PREDEFINED_REPORT: "PREDEFINED_REPORT_PAGEID",
  REPORT_SENDER_EMAIL: "noreply@cemail.heartblr.org",
  SCHEDULER_LIST_PAGEID: "SCHEDULERLIST",
} as const;

export default CONSTANTS;
