import styled from "styled-components";

const AdminHeaderWrapper = styled.header`
  @media (min-width: 991px) {
    .navbar-nav {
      margin-right: 0 !important;
    }

    .dropdown-menu {
      min-width: 138px !important;
    }
  }

  @media (max-width: 991px) {
    #adminHeaderCollapse {
      visibility: hidden;
    }
  }

  .aui-skip-content {
    padding: 16px;
    color: #c10e21;
    position: absolute;
    left: -9999px;
    width: 100%;
    top: 0;
    text-align: center;
    background-color: #fff;
    &:focus {
      left: 0;
      z-index: 500;
    }
  }
  &.aui-pri-header {
    .aui-header-content {
      width: 100%;
    }
    .aui-pri-header-t {
      padding-left: 0px;
      padding-right: 0px !important;
      @media (min-width: 576px) {
        padding-left: 0px;
        padding-right: 0px !important;
      }
      @media (min-width: 768px) {
        padding-left: 0px;
        padding-right: 0px !important;
      }
      @media (min-width: 992px) {
        padding-left: 40px;
        /* padding-right: 40px !important; */
      }
    }
  }

  .logo-main {
    width: 35%;
    display: none;

    @media (max-width: 991px) {
      display: block;
      visibility: visible;
    }
  }

  @media (max-width: 992px) {
    .header-hamburger {
      margin: 0 0;
    }
  }

  .headerLogo-style {
    @media screen and (min-width: 768px) {
      width: 215px;
      height: 70px;
    }

    @media screen and (max-width: 767px) {
      width: 170px;
      height: 60px;
    }
  }
`;
export default AdminHeaderWrapper;
