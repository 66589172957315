import React, { useEffect } from "react";
import { SignInWrapper } from "./style";
import { useTranslation } from "react-i18next";
import SvgCeCmeLogo from "components/SvgComponent/CeCmeLogo";
import AdminFooter from "components/AdminFooter";

const HomePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const tokenData = localStorage.getItem("accessToken");
    if (tokenData === "" || tokenData === null || tokenData === undefined) {
    } else {
      window.userManager
        .signinRedirect()
        .then(function (data: any) {})
        .catch(function (err: any) {
          // console.log("signinRedirect error:", err);
        });
    }
  }, []);
  // const signin = async () => {
  //   // NEED TO REMOVE THIS PART OF LOGIC

  //   const url = config[config.env].apiEndpoints.account + "/adminLogin";
  //   const data1 = config[config.env].data;
  //   const response = await fetch(url, {
  //     method: "POST", // *GET, POST, PUT, DELETE, etc.
  //     mode: "cors", // no-cors, *cors, same-origin
  //     cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
  //     credentials: "same-origin", // include, *same-origin, omit
  //     headers: {
  //       "Content-Type": "application/json",
  //       // 'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //     redirect: "follow", // manual, *follow, error
  //     referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //     body: JSON.stringify(data1), // body data type must match "Content-Type" header
  //   });

  //   const res = await response.json();
  //   localStorage.setItem("accessToken", res.data.token.accessToken);

  //   history.push("/dashboard");
  // };

  const signin = () => {
    window.userManager
      .signinRedirect()
      .then(function (data: any) {})
      .catch(function (err: any) {
        // console.log("signinRedirect error:", err);
      });
  };
  return (
    <SignInWrapper>
      <div className="main-container">
        <div className="header-div d-flex">
          <div className="ce-main-logo-div d-flex">
              <SvgCeCmeLogo className="main-content-logo logo-main headerLogo-style" />
          </div>
        </div>
        <div className="mid-container d-flex">
          <div className="box-container d-flex">
            <div className="box d-flex">
              <div className="box-header d-flex w-100">
                <div className="box-logo">
                  <img src={process.env.PUBLIC_URL + "/images/aha-logo-heart.svg"} alt="" 
                  aria-label={t<string>("American Heart Association Logo")}/>
                </div>
                <div className="aha-title-div d-flex" aria-label={t<string>("American Heart Association")}> 
                  <p>{t<string>("American Heart Association")}</p>
                </div>
              </div>
              <div className="box-body d-flex w-100 flex-column">
                <div className="box-body-title d-flex">
                  <p>
                    {t<string>("Welcome to CE Service")}
                  </p>
                </div>
                <div className="box-body-text d-flex">
                  <p>
                  {t<string>("Continuing education (CE) services application is a stand-alone system that interfaces with multiple vendor systems (Internal and external LMS), manages accreditation credit claim program details, performs a real time validation on individual learner activity received from each system and generate reports for AHA.")}
                  </p>
                  </div>

              </div>
              <button
                type="button"
                onClick={signin}
                className="btn btn-round btn-primary d-flex"
              >
                {/* mt-lg-n3 px-lg-5 px-md-5 px-sm-0 mx-auto mx-lg-0 d-block mb-md-9 */}
                {t<string>("signIn")} &nbsp;|&nbsp; {t<string>("signUp")}
              </button>
            </div>
           </div>
           <div className="backdrop-div">
            <img src={process.env.PUBLIC_URL + "/images/iStock-941446382_super.jpg"} alt="" />
            </div>
          </div>
          <AdminFooter />
      </div>
    </SignInWrapper>
  );
};

export default HomePage;
