import styled from "styled-components";

const AdminWrapper = styled.div`
  height: 100%;

  @media (max-width: 991px) {
    .left-panel {
      .main {
        margin: 0px !important;
      }

      .left-nav-bar,
      .sidenav {
        // display: none;
      }

      .left-nav-bar-show {
        display: block;
        width: 100% !important;
      }

      .left-panel-logo {
        visibility: hidden;
      }

      .aui-sidenav {
        margin-bottom: 0px;
      }

      .logo {
        position: relative;

        .close {
          display: block !important;
          position: absolute;
          right: 1.5rem;
          color: #8e8e8e;
          font-size: 25px;
        }
      }

      .main-content {
        width: 100%;
        clear: both;

        .main-content-logo {
          // visibility: visible !important;
          display: block;
        }
      }

      .main-content-hide {
        display: none !important;
      }
    }
  }

  .left-panel {
    display: flex;
    width: 100%;
    height: 100%;

    .sidenav {
      height: 100%;
      width: 20%;
      position: fixed;
      z-index: 99;
      top: 0;
      left: 0;
      background-color: #f8f8f8;
      padding-top: 0px;
      transition: width 0.5s;
      overflow-y: auto;

      @media screen and (min-width: 992px) {
        /*  overflow:visible; */
      }

      @media screen and (max-width: 991px) {
        width: 0;
        left: unset;
        right: 0;
        transition: width 0.5s;
        overflow-y: scroll;
      }
    }

    .main {
      margin-left: 20%; /* Same as the width of the sidenav */
      padding: 0px 0px;
    }

    .logo {
      .close {
        display: none;
      }
    }

    .left-panel-contents {
      height: 100%;
    }

    .main-content {
      display: flex;
      flex-direction: column;

      .main-content-logo {
        // visibility: hidden;
      }
    }

    .aui-sidenav ul {
      padding-top: 0px;

      li .btn {
        color: #fff;
        line-height: 1.5;
      }
    }

    .aui-sidenav-acc-header {
      background: #c10e21 0% 0% no-repeat padding-box;
      color: #fff;
      border-radius: 0;
      margin-bottom: 0.1rem;
    }

    .aui-sidenav-acc-header.parentHighlight {
      background: #85000e 0% 0% no-repeat padding-box;
    }

    // .active {
    //   background-color: #fff;
    //   color: black;
    // }

    .aui-sidenav-acc-body li a {
      color: #222328;
    }

    .aui-sidenav {
      width: 100%;
      background: #f8f8f8 0% 0% no-repeat padding-box;

      .navbar {
        height: 135px;
      }
    }
  }

  .aui-sidenav ul li a.active,
  .aui-sidenav ul li .btn.active {
    background: inherit;
    color: #c10e21;
  }

  .left-panel .aui-sidenav-acc-body li a:focus,
  .left-panel .aui-sidenav-acc-header:focus {
    box-shadow: none !important;
    outline: 0 !important;
  }

  ul.aui-sidenav-acc-body.collapse li a.active {
    background: inherit;
    color: #c10e21;
  }

  .aui-sidenav .aui-sidenav-acc-body li a {
    @media screen and (min-width: 992px) {
      padding-left: 1.875rem;
    }

    @media (min-device-width: 320px) and (max-device-width: 575px) {
      padding-left: 0.975rem;
    }

    @media (min-device-width: 576px) and (max-device-width: 767px) {
      padding-left: 1.25rem;
    }
    @media (min-device-width: 768px) and (max-device-width: 991px) {
      padding-left: 1.875rem;
    }
  }

  .aui-custom-switch
    .custom-control-input:checked
    ~ .custom-control-label:before {
    border-color: #0d8200;
  }

  .aui-custom-switch
    .custom-control-input:checked
    ~ .custom-control-label:after {
    background-color: #0d8200;
  }

  #maincontent {
    min-height: 100vh;
  }

  @media screen and (min-width: 992px) {
    .left-panel .left-nav-bar {
     overflow-y: auto;
     overflow-x:hidden;
    
    }
  }

  #customizetabletablelist {
    text-align: left !important;
  }
`;
export default AdminWrapper;
