export const ProgramTypes = {
  LIVE: "LIVE",
  ENDURING: "ENDURING",
  INTERNET_LIVE: "INTERNET_LIVE",
  JOURNALS: "JOURNALS",
  PIM: "PIM",
  COURSE: "COURSE",
} as const;

export type ProgramTypes = typeof ProgramTypes[keyof typeof ProgramTypes];
