import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ActivityLogManagementState {
  pageNumber: number;
  search: string;
  filterByVendor: string;
  filterByDate: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
}

export const initialState: ActivityLogManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
  filterByVendor: "",
  filterByDate: "",
};

const activityLogManagementSlice = createSlice({
  name: "activityLog",
  initialState,
  reducers: {
    setPageNumber: (
      state,
      action: PayloadAction<ActivityLogManagementState>
    ) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchActivityLog: (state, action: PayloadAction<string>) => {
      state.pageNumber = 1;
      state.search = action.payload;
    },
    filterByVendor: (state, action: PayloadAction<string>) => {
      state.filterByVendor = action.payload;
    },
    filterBydate: (state, action: PayloadAction<string>) => {
      state.filterByDate = action.payload;
    },
    setPageSize: (state, action: PayloadAction<ActivityLogManagementState>) => {
      state.pageSize = action.payload.pageSize;
      state.pageNumber = 1;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearActivityLogState: () => {
      return initialState;
    },
  },
});
const { actions, reducer } = activityLogManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchActivityLog,
  filterByVendor,
  filterBydate,
  setPageSize,
  clearActivityLogState,
} = actions;
export default reducer;
