export const reportTypes = [
  {
    name: "REGISTRATION REPORT BY PROGRAM",
    code: "registration_report_by_program",
  },
  {
    name: "ACPE LIVE",
    code: "acpe_live",
  },
  {
    name: "ACPE ENDURING",
    code: "acpe_enduring",
  },
  {
    name: "CREDIT REPORT BY PROGRAM",
    code: "credit_report_by_program",
  },
  {
    name: "CREDIT REPORT BY DATE",
    code: "credit_report_by_date",
  },
  {
    name: "MONTHLY CAPCE REPORT",
    code: "monthly_capce_report",
  },
  {
    name: "CREDIT SUMMARY REPORT",
    code: "credit_summary_report",
  },
  {
    name: "MOC - ABIM",
    code: "moc_abim",
  },
  {
    name: "MOC - ABP",
    code: "moc_abp",
  },
  {
    name: "USERS CLAIMING MOC - SCHEDULED",
    code: "users_claiming_moc_scheduled",
  },
] as const;

export const reportTypeCodeMap = reportTypes.reduce(
  (acc: { [key: string]: string }, curr) => {
    acc[curr.code] = curr.name;
    return acc;
  },
  {}
);

export const reportFrequency = [
  {
    name: "Last Month",
    code: "monthly",
  },
  {
    name: "Last Quarter",
    code: "quarterly",
  },
  {
    name: "Last Half Year",
    code: "half_yearly",
  },
  {
    name: "Last Year",
    code: "yearly",
  },
  {
    name: "Custom Range",
    code: "custom_range",
  },
];

export const reportFrequencyCodeMap = reportFrequency.reduce(
  (acc: { [key: string]: string }, curr) => {
    acc[curr.code] = curr.name;
    return acc;
  },
  {}
);

export const exportType = [
  {
    name: "XLSX",
    code: "xlsx",
  },
  {
    name: "CSV",
    code: "csv",
  },
];

export const exportTypeMap = exportType.reduce(
  (acc: { [key: string]: string }, curr) => {
    acc[curr.code] = curr.name;
    return acc;
  },
  {}
);
