export const Routes = {
  HOME_PAGE: "/",

  VENDOR_LIST_PAGE: "/vendors",
  VENDOR_ADD_PAGE: "/vendors/add",
  VENDOR_EDIT_PAGE: "/vendors/edit/:id",
  VENDOR_VIEW_PAGE: "/vendors/:id",

  ACTIVITY_VIEW_EDIT_PAGE: "/activities/:id",
  ACTIVITY_ERROR_VIEW_EDIT_PAGE: "/activity-errors/:id",

  ARCHIVE_LEARNER_LIST_PAGE: "/archived-learners",
} as const;
