import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { APIPermission } from "common/types/APIPermission";
import type { Permission } from "common/types/Permission";

export interface UserManagementState {
  pageNumber: number;
  search: string;
  pageSize: number;
  loader: boolean;
  pageOrder: string;
  apiPermissions: null | APIPermission;
  permissions: null | Permission;
}

export const initialState: UserManagementState = {
  pageNumber: 1,
  search: "",
  pageSize: 25,
  loader: false,
  pageOrder: "updated_at DESC",
};

const userManagementSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setPageNumber: (state, action: PayloadAction<UserManagementState>) => {
      state.pageNumber = action.payload.pageNumber;
    },
    searchUser: (state, action: PayloadAction<UserManagementState>) => {
      state.pageNumber = 1;
      state.search = action.payload.search;
    },
    setPageSize: (state, action: PayloadAction<UserManagementState>) => {
      state.pageNumber = 1;
      state.pageSize = action.payload.pageSize;
    },
    setPageOrder: (state, action) => {
      state.pageOrder = action.payload.pageOrder;
    },
    setLoader: (state, action: PayloadAction<boolean>) => {
      state.loader = action.payload;
    },
    clearUserState: (state) => {
      return { ...initialState, apiPermissions: state.apiPermissions };
    },
  },
});
const { actions, reducer } = userManagementSlice;

export const {
  setPageNumber,
  setLoader,
  setPageOrder,
  searchUser,
  setPageSize,
  clearUserState,
} = actions;
export default reducer;
